import React from "react"
import logo_en from "../assets/educate-magis-en.svg"
import logo_es from "../assets/educate-magis-es.svg"
import logo_fr from "../assets/educate-magis-fr.svg"
import TranslateLink from './translateLink'
import { FormattedMessage } from 'react-intl'
import Hr from "../components/horizontalRule"
import { FiExternalLink } from "react-icons/fi";

import Crate from '../components/crate';

class ComponentFooter extends React.Component {


  render() {

    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;
    const emUrl = (locale === 'en')
      ? 'https://www.educatemagis.org/'
      : `https://www.educatemagis.org/${locale}/`
    const vc2020Link = (locale === 'en')
      ? 'https://www.educatemagis.org/jesedu-jogja2020-virtual-colloquium/'
      : 'https://www.educatemagis.org/' + locale + '/jesedu-jogja2020-virtual-colloquium/';

    let logo = logo_en
    if (locale === 'es') {
      logo = logo_es
    }
    else if (locale === 'fr') {
      logo = logo_fr
    }

    return (
      <Crate className="bg-purple-900 mt-1 mb-8">
        <div className="container mx-auto bg-purple-900">
          <div className="flex flex-wrap items-end">
            <div className="w-full sm:w-1/2">
              <div className="block text-center sm:text-left">
                <p className="tk-museo text-mmd text-yellow-900 mb-3">
                  <FormattedMessage id="JESEDU-Jogja2020" />
                </p>
                <Hr />
                <ul className="text-omd font-semibold text-white mt-3">
                  <li>
                    <a href={vc2020Link} target="_blank" rel="noopener noreferrer">
                      <FormattedMessage id="Virtual Colloquium" />
                      &nbsp;<sup><FiExternalLink class="inline-block" /></sup>
                    </a>
                  </li>
                  <li>
                    <TranslateLink to="/">
                      <FormattedMessage id="About The Colloquium" />
                    </TranslateLink>
                  </li>
                  {/* <li><TranslateLink to="/page-programme/"> */}
                    {/* <FormattedMessage id="Programme" /> */}
                  {/* </TranslateLink> */}
                  {/* </li> */}
                  <li><TranslateLink to="/page-speakers/">
                    <FormattedMessage id="Speakers" />
                  </TranslateLink>
                  </li>
                  <li><TranslateLink to="/page-participants-accommodation/">
                    <FormattedMessage id="Transportation Accommodation" />
                  </TranslateLink>
                  </li>
                  <li><TranslateLink to="/page-participants-pilgrimage/">
                    <FormattedMessage id="Pilgrimage" />
                  </TranslateLink>
                  </li>
                  {/* <li><TranslateLink to="/page-participants-registration/">
                    <FormattedMessage id="Registration" />
                  </TranslateLink>
                  </li> */}
                  <li>
                    <TranslateLink to="/page-contact/">
                      <FormattedMessage id="Contact Us" />
                    </TranslateLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full sm:w-1/2">
              <div className="block text-center sm:text-right">
                <p className="text-omd font-normal text-white my-1 text-osm">
                  <FormattedMessage id="Supported By" />
                </p>
                <a href={emUrl}>
                  <img className="mx-auto sm:ml-full sm:mr-0" style={{ width: `220px` }} src={logo} alt="Logo" />
                </a>
                <div className="text-oxs font-normal text-blue-300">
                  <TranslateLink to="/legals-cookie-policy/">
                    <FormattedMessage id="Cookie Policy" />
                  </TranslateLink>
                  &nbsp;|&nbsp;
                  <TranslateLink to="/legals-terms-of-use/">
                    <FormattedMessage id="Terms of Use" />
                  </TranslateLink>
                  &nbsp;|&nbsp;
                  <TranslateLink to="/legals-privacy-statement/">
                    <FormattedMessage id="Privacy Statement" />
                  </TranslateLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Crate>
    )
  }
}

export default ComponentFooter