import React from "react"
import { FormattedMessage } from 'react-intl'

import Crate from './crate'
import NavSide from './navSide'
import LanguageSelector from './languageSelector'

import iconMenu from "../assets/hamburger.svg"
import styled from "@emotion/styled"
import Img from "gatsby-image"

class ComponentHeader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menuActive: false,
    }

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  
  toggleMenu(e) {
    if (e.target.getAttribute('data-toggle') === 'menu') {
      this.openMenu();
    }
  }

  openMenu() {
    this.setState({ menuActive: true });
  }

  closeMenu(e) {
    console.log('closeMenu');
    if (e.target.getAttribute('data-toggle') === 'menu') {
      this.setState({ menuActive: false });
    }
  }

  render() {

    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;
    const menuActive = this.state.menuActive || false;    
    
    const Wrap = styled.div`
    @media only screen and (min-width: 992px) {
        height: 110px
      }
    `

    return (
      <Crate className="bg-purple-900">

        <NavSide isOpen={menuActive} closeMenu={this.closeMenu} locale={locale} headerImage={props.headerImage}/>

        <div className="container container-padded mx-auto py-1">
          <Wrap className="w-100">
            <div className="flex justify-between sm:justify-start">
              <div className="flex-grow-0 hidden md:block">
                <a href="/" className="block" style={{ paddingLeft: `20px`, paddingRight: `20px` }}>
                  <Img fixed={props.headerImage.childImageSharp.fixed} />
                </a>
              </div>
              <div className="flex-grow py-3 relative">
                <div className="flex flex-col sm:flex-row text-center items-center">
                  <div className="px-0 sm:pl-3 sm:pr-8 tk-museo font-semibold border-0 sm:border-r border-solid border-yellow-900">
                    <a href="/" className="block my-1 sm:my-3 leading-tight">
                      <span className="block text-pink-900 whitespace-no-wrap" style={{ fontSize: `18px` }}>
                        <FormattedMessage id="II COLLOQUIUM" />
                      </span>
                      <span className="block text-mmd text-yellow-900 whitespace-no-wrap">
                        <FormattedMessage id="JESEDU-Jogja2020" />
                      </span>
                    </a>
                  </div>
                  <div className="flex-grow-0 px-0 sm:pl-8 sm:pr-3 text-omd text-white">
                    <p className="my-1 sm:my-3 leading-tight">
                      <span className="font-semibold block whitespace-no-wrap"><FormattedMessage id="June 28–July 3, 2021" /></span>
                      <span className="font-light block whitespace-no-wrap"><FormattedMessage id="Yogyakarta, Indonesia" /></span>
                    </p>
                  </div>
                </div>
                <img className="block md:hidden absolute top-0 right-0 mt-3 mr-3" onClick={(e) => { this.toggleMenu(e) }} data-toggle="menu" src={iconMenu} alt="Logo" style={{ width: `50px`, height: `50px`, }} />
              </div>
              <div className="hidden md:block pt-3">
                <div className="">
                  <LanguageSelector locale={locale} />
                </div>
              </div>
            </div>
          </Wrap>
        </div>
      </Crate>
    )
  }
}

export default ComponentHeader
