import React from "react"
import TranslateLink from './translateLink'
import { FormattedMessage } from 'react-intl'
import Crate from '../components/crate';
import styled from "@emotion/styled"
import { globalHistory } from "@reach/router"
import { FiExternalLink, FiX } from "react-icons/fi";

const Center = styled.div`
  > div > span {
    border-bottom: 5px solid transparent;
  }
  > div > span.nav-active{
    border-bottom: 5px solid #FBB040;
  }
  > div > span > div{
    display:none;
  }
  > div > span:hover > div{
    display: block;
  }
`

const SubMenu = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: 200px;
  z-index: 999;
  > a {
    background-color: #FBB040;
  }
`

const itemClass = "px-4 py-1 block";

class ComponentNavTop extends React.Component {

  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      messageState: 'hide',
    };
  }

  navClass(elClass, paths) {
    const currentPath = globalHistory.location.pathname;
    return paths.includes(currentPath) ? elClass + " nav-active" : elClass;
  }

  toggleMessage = (event) => {
    this.setState({
      messageState: this.state.messageState === 'show' ? 'hide' : 'show'
    });
  }

  render() {

    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;
    const messageState = this.state.messageState

    const vc2020Link = (locale === 'en')
      ? 'https://www.educatemagis.org/jesedu-jogja2020-virtual-colloquium/'
      : 'https://www.educatemagis.org/' + locale + '/jesedu-jogja2020-virtual-colloquium/';

    return (
      <div>
        <Crate className="container-decoration container-decoration-top hidden md:block">
          <Center>
            <div className="flex h-10 bg-pink-900 rounded-tl-full items-end text-osm font-semibold text-white justify-end" style={{ marginLeft: `130px`, paddingLeft: `50px` }}>
              <span className={itemClass}>
                <a href={vc2020Link} target="_blank" rel="noopener noreferrer">
                  <sup><FiExternalLink class="inline-block" /></sup>&nbsp;
                  <FormattedMessage id="Virtual Colloquium" />
                </a>
              </span>
              <span className={this.navClass("", ["/"])}>
                <TranslateLink className={itemClass} to="/">
                  <FormattedMessage id="About The Colloquium" />
                </TranslateLink>
              </span>
              {/* <span className={this.navClass("", ["/page-programme/"])}> */}
                {/* <TranslateLink className={itemClass} to="/page-programme/"> */}
                  {/* <FormattedMessage id="Programme" /> */}
                {/* </TranslateLink> */}
              {/* </span> */}
              <span className={this.navClass("", ["/page-speakers/"])}>
                <TranslateLink className={itemClass} to="/page-speakers/">
                  <FormattedMessage id="Speakers" />
                </TranslateLink>
              </span>
              <span
                className={
                  this.navClass(
                    "px-1 py-1 block relative",
                    [
                      "/page-participants-accommodation/",
                      "/page-participants-pilgrimage/",
                      "/page-participants-registration/"
                    ]
                  )}>
                <FormattedMessage id="Participants" />
                <SubMenu>
                  <TranslateLink className="block border-b border-white px-3 py-1" to="/page-participants-accommodation/">
                    <FormattedMessage id="Transportation Accommodation" />
                  </TranslateLink>
                  <TranslateLink className="block border-b border-white px-3 py-1" to="/page-participants-pilgrimage/">
                    <FormattedMessage id="Pilgrimage" />
                  </TranslateLink>
                  {/* <TranslateLink className="block px-3 py-1" to="/page-participants-registration/">
                    <FormattedMessage id="Registration" />
                  </TranslateLink> */}
                </SubMenu>
              </span>
              <span className={this.navClass("", ["/page-contact/"])}>
                <TranslateLink className={itemClass} to="/page-contact/">
                  <FormattedMessage id="Contact Us" />
                </TranslateLink>
              </span>
            </div>
          </Center>
        </Crate>
        <Crate>
          <div className="flex justify-center bg-white px-5 md:px-0 text-red-700 font-semibold">
            <p>
              <FormattedMessage id="Official Announcement..." />&nbsp;
                <u onClick={this.toggleMessage}><FormattedMessage id="Read the full announcement..." /></u>
            </p>
          </div>
          {(messageState === 'show') &&
            <div class="pb-3">
              <div class="p-3 bg-blue-100 relative">
                <div onClick={this.toggleMessage} class="absolute top-0 right-0">
                  <FiX className='m-3' />
                </div>
                {(locale === 'en') &&
                  <div>
                    <h2>OFFICIAL ANNOUNCEMENT:</h2>
                    <h3>NEW DATES FOR THE COLLOQUIUM</h3>
                    <p><strong>13 March 2020</strong></p>
                    <p>This communique is a follow-up to the previous one announcing the postponement of II Colloquium JESEDU-Jogja2020.</p>
                    <p>We are delighted to inform that the new dates for the event have been confirmed. ICAJE has concurred that the Colloquium is rescheduled for June 28 – July 3, 2021. The new dates for this important global gathering of Jesuit school leaders now coincide with the 500th anniversary of the conversion of St. Ignatius of Loyola after the Battle of Pamplona in 1521. It will be an opportunity to do what Fr. General Arturo Sosa SJ, invites us to do during this Jubilee Year: "To offer...the best of what is ours in the genuine spirit of the Contemplation to Attain Love, in that communication of the two standards..."</p>
                    <p>We will keep you apprised of any future developments through a communique and/or the official website for the Jogja Colloquium.</p>
                    <div class="flex justify-between">
                      <div>
                        <p><strong>B. Widi Nugroho</strong><br />
                          <em>II JESEDU-Jogja2020 Coordinator</em></p>
                      </div>
                      <div>
                        <p><strong>Baskoro Poedjinoegroho, SJ</strong><br />
                          <em>II JESEDU-Jogja2020 Chairman</em></p>
                      </div>
                    </div>
                    <p>Noted by:</p>
                    <p>
                      <strong>José Alberto Mesa, SJ</strong><br />
                      <em>Secretary for Education</em><br />
                      <em>Society of Jesus</em><br />
                      <em>Curia Generalizia</em><br />
                      <em>Rome</em>
                    </p>
                  </div>
                }
                {(locale === 'es') &&
                  <div>
                    <h2>ANUNCIO OFICIAL:</h2>
                    <h3>NUEVAS FECHAS PARA EL COLOQUIO</h3>
                    <p><strong>13 de marzo de 2020</strong></p>
                    <p>Este comunicado es una continuación del anterior donde se anunciaba el aplazamiento del II Coloquio JESEDU-Jogja2020.</p>
                    <p>Nos complace poder informar que las nuevas fechas para el evento han sido confirmadas. ICAJE ha acordado que el Coloquio está reprogramado/pospuesto para llevarse a cabo del 28 de junio al 3 de julio de 2021. Las nuevas fechas para esta importante reunión mundial de líderes de escuelas jesuitas coincidirán con el 500 aniversario de la conversión de San Ignacio de Loyola después de la Batalla de Pamplona en 1521. Será una oportunidad para efectuar lo que el P. General Arturo Sosa SJ nos invita a hacer durante este Año Jubilar: "Ofrecer ... lo mejor de lo nuestro en el espíritu genuino de la Contemplación para Alcanzar el Amor, en esa comunicación de los dos estándares ..."</p>
                    <p>Les mantendremos informados de cualquier novedad a través de un comunicado y / o del sitio web oficial del Coloquio de Jogja.</p>
                    <div class="flex justify-between">
                      <div>
                        <p><strong>B. Widi Nugroho</strong><br />
                          <em>Coordinador II JESEDU-Jogja2020</em></p>
                      </div>
                      <div>
                        <p><strong>Baskoro Poedjinoegroho, SJ</strong><br />
                          <em>Presidente II JESEDU-Jogja2020</em></p>
                      </div>
                    </div>
                    <p>Comprobado por:</p>
                    <p><strong>José Alberto Mesa, SJ</strong><br />
                      <em>Secretario de Educación</em><br />
                      <em>Compañía de Jesús</em><br />
                      <em>Curia Generalizia</em><br />
                      <em>Roma</em></p>
                  </div>
                }
                {(locale === 'fr') &&
                  <div>
                    <h2>ANNONCE OFFICIELLE</h2>
                    <h3>NOUVELLES DATES DU COLLOQUE</h3>
                    <p><strong>13 March 2020</strong></p>
                    <p>Ce communiqué fait suite au précédent annonçant le report du II Colloque JESEDU-Jogja2020.</p>
                    <p>Nous sommes ravis de vous informer que les nouvelles dates de l'événement ont été confirmées. L'ICAJE a convenu que le colloque est reporté pour la période du 28 juin au 3 juillet 2021. Les nouvelles dates de cet important rassemblement mondial de chefs d'établissements jésuites coïncident désormais avec le 500e anniversaire de la conversion de Saint Ignace de Loyola après la bataille de Pampelune en 1521. Ce sera l'occasion de réaliser ce que le Père Général Arturo Sosa SJ nous invite à faire durant cette Année Jubilaire: « Offrir ... le meilleur de ce qui est à nous dans l'esprit authentique de la Contemplation pour Atteindre l'Amour, dans cette communication des deux standards ... »</p>
                    <p>Nous vous tiendrons informés de tout développement futur via un communiqué et / ou sur le site officiel du Colloque Jogja.</p>
                    <div class="flex justify-between">
                      <div>
                        <p><strong>B. Widi Nugroho</strong><br />
                          <em>IIe JESEDU-Jogja2020 Coordinateur</em></p>
                      </div>
                      <div>
                        <p><strong>Baskoro Poedjinoegroho, SJ</strong><br />
                          <em>IIe JESEDU-Jogja2020 Président</em></p>
                      </div>
                    </div>
                    <p>Noté par :</p>
                    <p><strong>José Alberto Mesa, SJ</strong><br />
                      <em>Secrétaire à l’Éducation</em><br />
                      <em>Compagnie de Jésus</em><br />
                      <em>Curia Generalizia</em><br />
                      <em>Rome</em></p>
                  </div>
                }
              </div>
            </div>
          }

        </Crate >
      </div >
    )
  }
}

export default ComponentNavTop